import './App.css';
import './portedCss.css';
import $ from "jquery";
import { useEffect, useState } from 'react';
import Column from './Components/ported/column';

function App() {

  const [initLoad, setInitLoad] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    setInitLoad(true);
  });

  const toggleMobileMenu = (direction) => {
    if(direction){
      $(".mobile-menu").show();
      $(".grey-out").show();
    } else {
      $(".mobile-menu").hide();
      $(".grey-out").hide();
    }
  }

  useEffect(() => {
    console.log("test");
    toggleMobileMenu(showMobileMenu);
  }, [showMobileMenu])

  return (
    <div className="App">
      <div className={`bg ${initLoad ? 'fade-in' : ''}`}/>
      
      <div className="top-bar">
        <div className="logo"></div>

        <div className="company-logo"/>

        <div className="dropdown-selectable selected cyberthreat">
          <a href="/"><span>CyberThreat <i class="fa-solid fa-chevron-down"></i></span></a>
          <div className="drop-down">
            {/* <a href="characters">Characters</a>
            <a className="" href="gallery">Gallery</a> */}
            <a target="_blank" href="https://www.indiedb.com/games/cyberthreat/presskit">Press Kit <i className="fas fa-external-link-alt"></i></a>
          </div>
        </div>

        <div className="dropdown-selectable portfolio">
          <a target="_blank" href="portfolio/index"><span>Portfolio <i className="fas fa-external-link-alt"></i></span></a>
        </div>
      </div>


      
      <div className="dropdown-selectable ">
        <a href="contact.html"><span>Contact</span></a>
      </div>

      <div className="top-bar mobile">
        <div className="hamburger">
          <i className="fas fa-bars" onClick={() => setShowMobileMenu(true)}></i>
        </div>
      </div>

      <div className="grey-out"  onClick={() => setShowMobileMenu(false)}></div>

      <div className="mobile-menu">
        <div className="mobile-nav-option nav-title"><i className="fas fa-chevron-right" onClick={() => setShowMobileMenu(false)}></i>&nbsp;</div>
        <a href="/"><div className="mobile-nested-option">CyberThreat</div></a>
        <a target="_blank" href="https://www.indiedb.com/games/cyberthreat/presskit"><div className="mobile-nested-option">Press Kit <i className="fas fa-external-link-alt"></i></div></a>
      
        {/* <a target="_blank" href="portfolio/index.html"><div className="mobile-nested-option">Portfolio <i className="fas fa-external-link-alt"></i></div></a> */}
      </div>

      <div className="content-main">
        {/* <div className="header-image-home"></div> */}
        <div className="header-video">
          <iframe width="100%" height="500" src="https://www.youtube.com/embed/bn5QwlH1x_w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="call-to-action-steam">
          <iframe src="https://store.steampowered.com/widget/1395040/" frameborder="0" width="600px" height="200px"></iframe>
        </div>
        <h1></h1>
        

        <Column colSize="col-66">
          <h2>CyberThreat</h2>
          <span className="intro">When the lines between cyberspace and reality are blurred, how far will you go to save those you care for?</span><br/>
          <span className="intro">CyberThreat is an upcoming Indie, modern-day, turn-based RPG set in the fictional city of Edendale.</span><br/>
          <span className="intro">You arrive towards the end of your penultimate school year. At the same time as your arrival, serial disappearance cases are occuring across the city, and cyberbullying is at an all time high.</span><br/>
          <span className="intro">Juggle your daily life with battles set in the depths of Cyberspace. Meet a cast of over 25 unique characters, each with their own story to tell. Uncover the truth behind the serial disappearances when you become a victim of Cyberbullying yourself.</span><br/>
          <span className="intro">Your adventure in CyberThreat awaits!</span><br/>

          <h2>Stay up to date</h2>
          <span className="intro">Check out some of the links below, and be sure to follow the official channels and join the CyberThreat communities!</span><br/>
          <span className="social-media-styled-buttons">
            <a href="https://twitter.com/cyberthreatgame" target="blank_"  className="social-media-styled-button twitter-button"><i className="fab fa-twitter"></i> Twitter</a>
            <a href="https://www.youtube.com/channel/UCQDeCtl_F-bPIh-3oD11avA/" target="blank_"  className="social-media-styled-button youtube-button"><i className="fab fa-youtube"></i> YouTube</a>
            <a href="https://instagram.com/cyberthreatgame" target="blank_" className="social-media-styled-button instagram-button"><i className="fab fa-instagram"></i> Instagram</a>
            <a href="https://facebook.com/cyberthreatgame" target="blank_"  className="social-media-styled-button facebook-button"><i className="fab fa-facebook"></i> Facebook</a>
            <a href="https://discord.gg/6QtAdm" target="blank_"  className="social-media-styled-button discord-button"><i className="fab fa-discord"></i> Discord</a>
            <a href="https://soundcloud.com/cyberthreatgame" target="blank_"  className="social-media-styled-button soundcloud-button"><i className="fab fa-soundcloud"></i> Soundcloud</a>
            <a href="https://reddit.com/r/cyberthreat" target="blank_"  className="social-media-styled-button reddit-button"><i className="fab fa-reddit-alien"></i> Reddit</a>
            <a href="https://www.twitch.tv/cyberthreat_dev" target="blank_"  className="social-media-styled-button twitch-button"><i className="fab fa-twitch"></i> Twitch</a>
          </span><br/>
        </Column>

        <Column colSize="col-33">
          <h2>Tweets</h2>
          <a className="twitter-timeline" data-height="600" data-theme="light" href="https://twitter.com/cyberthreatgame?ref_src=twsrc%5Etfw">Tweets by cyberthreatgame</a> 
          
        </Column>
      <div className="bottom-padding"></div>
    </div>

    <table className="bottom-bar">
      <tr>
        <td>
          <a href="https://www.facebook.com/cyberthreatgame/" target="_blank"><div className="social-media"><i className="fab fa-facebook-f"></i></div></a>
          <a href="https://www.twitter.com/cyberthreatgame/"><div className="social-media"><i className="fab fa-twitter"></i></div></a>
          <a href="https://www.youtube.com/channel/UCQDeCtl_F-bPIh-3oD11avA"><div className="social-media"><i className="fab fa-youtube"></i></div></a>
          <a href="https://discord.gg/eBavEVz"><div className="social-media"><i className="fab fa-discord"></i></div></a>
          <a href="https://soundcloud.com/cyberthreatgame"><div className="social-media"><i className="fab fa-soundcloud"></i></div></a>
          <a href="https://www.twitch.tv/cyberthreat_dev"><div className="social-media"><i className="fab fa-twitch"></i></div></a>
          <a href="https://www.instagram.com/cyberthreatgame/"><div className="social-media"><i className="fab fa-instagram"></i></div></a>
        </td>
      </tr>
    </table>
    

    </div>
  );
}

export default App;
